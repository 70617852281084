import Vue from 'vue'
import Vuex from 'vuex'
import { $dataRoomStore } from '@gcpaas/data-room-ui'
import { $dashboardStore } from '@gcpaas/dash-board-ui'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    bigScreen: $dataRoomStore,
    dashboard: $dashboardStore
  }
})
