import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

/*========================================引入ElementUI>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<引入ElementUI========================================*/

/*========================================引入DataRoom>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
import { registerConfig as registerConfigDataRoom, $dataRoomAxios } from '@gcpaas/data-room-ui'
registerConfigDataRoom({
  starter: {
    title: '可视化设计器',
    logo: require('@/assets/logo.png')
  },
  httpConfigs: {
    baseURL: process.env.VUE_APP_DATA_ROOM_BASE_URL,
    fileUrlPrefix: process.env.VUE_APP_DATA_ROOM_FILE_URL_PREFIX
  },
  sourceExtends: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp', 'ico', 'xls', 'xlsx', 'csv']
}, router)
Vue.prototype.$dataRoomAxios = $dataRoomAxios
/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<引入DataRoom=========================================*/

/*========================================引入DashBoard>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
import { registerConfig as registerConfigDashboard, $dashboardAxios } from '@gcpaas/dash-board-ui'
registerConfigDashboard({
  starter: {
    title: '仪表盘设计器',
    logo: require('@/assets/logo.png')
  },
  httpConfigs: {
    baseURL: process.env.VUE_APP_DATA_ROOM_BASE_URL,
    fileUrlPrefix: process.env.VUE_APP_DATA_ROOM_FILE_URL_PREFIX
  },
  sourceExtends: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp', 'ico', 'xls', 'xlsx', 'csv']
}, router)
Vue.prototype.$dashboardAxios = $dashboardAxios
/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<引入DashBoard=========================================*/

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
